/* Контент в 100% */
@media screen and (max-width: 900px) {
    .fullscreen {
        width: 100%;
        flex: none;
        display: flex;
        justify-content: center;
        align-items: flex-start;

        margin-top: calc(var(--button-height) + var(--gap-small));
    }

    .fullscreen__controls {
        flex-direction: row;
        top: calc(-1 * var(--button-height));
        right: 50%;
        transform: translate(50%, 0);
        z-index: var(--z-main);
    }

    /* Блок удерживающий картинку по центру */
    .fullscreen__image-wrapper {
        height: 100%;
        margin-top: var(--gap-small);
        margin-bottom: 60px;
    }

    .fullscreen__image-wrapper img {
        max-width: calc(100% - var(--gap-small) - var(--gap-small));
    }
}