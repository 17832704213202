:root {
    --border-input: #666666;
    --border-input-focus: #1CA02C;
    --border-input-error: #D60027;
}

/* Основной стиль инпута */
input[type=text] {
    display: block;
    width: 100%;
    height: var(--button-height); /* Минимальный размер равен высоте кнопки */
    padding: var(--button-padding);

    color: var(--text-white);
    font-size: var(--p);
    text-decoration: none;

    outline: none;
    background-color: var(--bg-secondary);
    border: 1px solid var(--border-input);
    border-radius: var(--border-radius);
}

/* Текстареа */
textarea {
    display: block;
    width: 100%;
    height: 200px; /* По умолчанию высота в 200px */
    min-height: var(--button-height); /* Минимальный размер равен размеру инпута */
    max-height: 500px; /* Максимальный размер */
    padding: 8px var(--button-padding) var(--button-padding) var(--button-padding); /* Сверху отступ больше */

    color: var(--text-white);
    font-size: var(--p);
    text-decoration: none;

    outline: none;
    background-color: var(--bg-secondary);
    border: 1px solid var(--border-input);
    border-radius: var(--border-radius);

    resize: vertical; /* resize только вертикальный */
}


/* Текст стилизованный под текстареа */
.textarea-block {
    width: 100%;
    padding: var(--button-padding);

    color: var(--text-light);
    text-decoration: none;

    background-color: var(--bg-secondary);
    border: 1px solid var(--border-input);
    border-radius: var(--border-radius);

    white-space: pre-wrap;
    cursor: not-allowed;
}


/* При выборе инпута / текстареа (Кроме неизменяемых) */
input[type=text]:not(:read-only):focus,
textarea:not(:read-only):focus {
    border: 1px solid var(--border-input-focus);
}

/* Неизменяемые с серым текстом */
input[type=text]:read-only,
textarea:read-only {
    color: var(--text-gray);
    cursor: not-allowed;
}

/* Плейсхолдер */
input[type=text]::placeholder {
    color: var(--text-dark);
}

/* Фикс авто ввода */
input[type=text]:-webkit-autofill, 
input[type=text]:-webkit-autofill:focus {
    transition: background-color 600000s 0s, color 600000s 0s;
}


/* Радио инпут */
input[type=radio] {
    width: 16px;
    height: 16px;
    position: relative;
    background-color: #ffffff;
    border-radius: 50%;

    appearance: none;
}

input[type="radio"]::before {
    content: "";
    width: 10px;
    height: 10px;

    border-radius: 50%;
    background-color: var(--bg-secondary);

    position: absolute;
    top: 3px;
    left: 3px;

    visibility: hidden;
}

input[type="radio"]:checked::before {
    visibility: visible;
}