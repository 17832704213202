aside {
    width: 300px;
    background-color: var(--bg-secondary);
    border-right: 1px solid var(--border-primary);
    /* border-radius: var(--border-radius); */
    padding: var(--gap-medium);
}

aside > a .button-profile img {
    background-color: transparent;
}