:root {
    /* Цвета кнопок */
    --button-bg: #322F39;
    --button-hover: #55515C;

    --button-bg-green: #006500;
    --button-hover-green: #008810;

    --button-bg-red: #7A141A;
    --button-hover-red: #A91922;

    --button-bg-tp: #FFFFFF00;
    --button-hover-tp: #FFFFFF1F;

    --button-bg-clicked: #4b4753;
    --button-bg-disabled: #2E2C35;

    /* Обычная кнопка (картинка на 12px меньше - 6px отступ) */
    --button-height: 36px;
    --button-img: 24px;

    /* Большая кнопка (для профиля и тд.) */
    --button-height-big: 48px;
    --button-img-big: 36px;

    /* padding и gap для одинакового значения с другими элементами */
    --button-padding: 4px;
    --button-gap: 4px;

}

/* Обычная кнопка (Серая) */
button {
    width: min(200px, 100%);
    min-height: var(--button-height);
    padding: var(--button-padding);

    display: flex;
    justify-content: center;
    align-items: center;
    gap: var(--button-gap);

    color: var(--text-white);
    font-size: var(--p);
    text-decoration: none;

    outline: none;
    border: none;
    border-radius: var(--border-radius);
    background-color: var(--button-bg);

    overflow: hidden;
    user-select: none;
    cursor: pointer;
}

/* Важная кнопка (Зеленая) */
button.green {
    background-color: var(--button-bg-green);
}

/* Опасная кнопка (Красная) */
button.red {
    background-color: var(--button-bg-red);
}

/* Кнопка в списке (Прозрачная) */
button.tp {
    background-color: var(--button-bg-tp);
}


/* Отключенная кнопка */
button:disabled {
    cursor: not-allowed;
    color: var(--text-dark);
    background-color: var(--button-bg-disabled);
}

/* Текст внутри отключенной кнопки */
button:disabled p {
    color: var(--text-dark);
}

/* Иконка внутри отключенной кнопки */
button:disabled img {
    opacity: .3;
}

/* Нажатая кнопка */
button.clicked {
    background-color: var(--button-bg-clicked);
}


/* Наведение на пк */
@media (hover: hover) {
    button:hover,
    button:focus-visible {
        background-color: var(--button-hover);
    }

    button.green:hover,
    button.green:focus-visible {
        background-color: var(--button-hover-green);
    }

    button.red:hover,
    button.red:focus-visible {
        background-color: var(--button-hover-red);
    }

    button.tp:hover,
    button.tp:focus-visible {
        background-color: var(--button-hover-tp);
    }

    button:disabled:hover,
    button:disabled:focus-visible {
        background-color: var(--button-bg-disabled);
    }
}


/* Клик на телефоне */
@media (hover: none) {
    button:active {
        background-color: var(--button-hover);
    }

    button.green:active {
        background-color: var(--button-hover-green);
    }

    button.red:active {
        background-color: var(--button-hover-red);
    }

    button.tp:active {
        background-color: var(--button-hover-tp);
    }

    button:disabled:active {
        background-color: var(--button-bg-disabled);
    }
}