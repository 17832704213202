/* Полноэкранный просмотр */
.fullscreen {
    flex: 1;
    height: 100%;
    position: relative;
}

/* Кнопки управления */
.fullscreen__controls {
    position: absolute;
    top: 50%;
    right: calc(var(--gap-medium) + 4px);
    transform: translateY(-50%);
    z-index: var(--z-over);
}


/* Отключаем transition */
.fullscreen div {
    transition: 0s all;
}

/* Блок удерживающий картинку по центру */
.fullscreen__image-wrapper {
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: grab;
}

.fullscreen__image-wrapper img {
    max-width: calc(100% - var(--gap-medium) - var(--gap-medium));
    max-height: 100%;
    object-fit: contain;
}