html, body {
    width: 100%;
    height: 100%;
}

body {
    background-color: var(--bg-primary);
}

/* Блок страницы */
article {
    width: 100%;
    height: 100%; /* Высота во всю страницу */
    min-height: calc(600px + 170px);
    display: flex; /* Aside и карта в строку  */
}

/* Разделитель */
hr {
    width: 100%;
    border: none; /* Отключаем стандартную обводку и ставим обводку 1px */
    border-bottom: 1px solid var(--border-primary);
}


/* Блок - флекс строка */
.flex-row {
    display: flex;
    flex-direction: row;
    gap: var(--gap-small);
}

/* Блок - флекс колонна */
.flex-col {
    display: flex;
    flex-direction: column;
    gap: var(--gap-small);
}